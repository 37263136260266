import { type ParsedUrlQuery } from "querystring";
import { createDecodedFilterParams } from "./createDecodedFilterParams";
import * as queryString from "@/features/list-screen/constants/query-string";

/**
 * * `filter` key の value をデコードし、デコードしたオブジェクトを引数 `query` にマージする。
 * * `filter` key は削除する。
 */
export const convertFilterKey = <
  T extends ParsedUrlQuery & { [key in typeof queryString.FILTER]?: string },
>(
  query: T,
): Omit<T, typeof queryString.FILTER> => {
  let ret = { ...query };
  delete ret[queryString.FILTER];
  ret = { ...createDecodedFilterParams(query), ...ret };
  return ret;
};
