import styled from "@emotion/styled";
import { Container, Tab, Tabs, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BusinessIcon from "@mui/icons-material/Business";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MessageIcon from "@mui/icons-material/Message";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ToysIcon from "@mui/icons-material/Toys";
import MoneyIcon from "@mui/icons-material/Money";
import { type PropsWithChildren, type FC } from "react";
import { createPortal } from "react-dom";
import { useRouter } from "next/router";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ChildCare from "@mui/icons-material/ChildCare";
import SettingsBackupRestore from "@mui/icons-material/SettingsBackupRestore";
import { MainLayoutErrorBoundary } from "./_components/MainContentErrorBoundary";
import {
  useFullScreenDisclosure,
  useMenuDisclosure,
} from "../../providers/local-storage";
import FilterTags from "@/components/fragments/filter-tags/FilterTags";
import link from "@/helper/link";
import { ModeContext } from "@/contexts/mode";
import couponPath from "@/features/coupons/constant";
import { type MenuEnum, type ScreenMode } from "@/types";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";
import { useMadrasAPI } from "@/api/hooks";
import { useIsMobile } from "@/hooks/useMediaQuery";
import { mediaQuery } from "@/styles/emotion/mediaQuery";
import { mainMenuLabels } from "@/constants/mainMenuLabels";

const HeaderBar = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-bottom: 10px;
  span {
    justify-content: flex-start;
  }
`;

export const HEADER_BAR_STICKY_HEIGHT = 152;
const HeaderBarSticky = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  padding-top: 5px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 20;
  padding-left: 10px;
`;

const PageTitle = styled(Typography)`
  margin-bottom: 16px !important;
`;

const MainContent = styled.div`
  padding-left: 16px;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 48px;
`;

const FullMainContent = styled.div`
  margin-top: 20px;
`;

const Footer = styled.div`
  position: relative;
  border-top: 1px solid #cccccc;
  padding-top: 8px;
  margin-top: 32px;
  margin-bottom: 128px;
  display: flex;
  z-index: -10;
  justify-content: space-between;
  ${mediaQuery.mobile`
    flex-direction: column;
  `}
  flex-direction: row;
`;
const Logo = styled.div`
  position: relative;
  z-index: -10;
  margin-left: 6px;
  margin-top: 3px;
`;
const VersionInfo = styled.div`
  color: #cccccc;
  font-size: 10px;
  ${mediaQuery.mobile`
    margin-left: 0px;
    `}
    margin-top: 10px;}
`;

const MainContainer = styled(Container)`
  min-width: 1200px;

  &:not(.is-fullscreen) {
    max-width: 1200px;
  }
  ${mediaQuery.mobile`
    min-width: initial;
  `}
`;

const BottomNavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: scroll;
  z-index: 100;
  border-top: 2px solid #eee;
  background-color: #ffffff;
`;

const TabLayout = styled(Tab)`
  min-width: 120px;
`;

const MenuButton = styled(Button)`
  width: calc(100% - 5px);
`;

const MenuButtonContainer = styled.div`
  width: 20%;
  margin-top: 5px;
`;

type Menu = {
  link: string;
  icon: React.ReactElement;
  /**
   * トップ画面は取り除く
   */
  label: Exclude<MenuEnum, "">;
};

const menuItems: Menu[] = [
  { link: "/customer", icon: <SupervisorAccountIcon />, label: "customer" },
  { link: "/toy", icon: <ToysIcon />, label: "toy" },
  { link: "/plan", icon: <ListAltIcon />, label: "plan" },
  { link: "/planner", icon: <MessageIcon />, label: "planner" },
  { link: "/inventory", icon: <PlaylistAddCheckIcon />, label: "inventory" },
  { link: "/inspection", icon: <LibraryAddCheckIcon />, label: "inspection" },
  { link: "/stock", icon: <ImportExportIcon />, label: "stock" },
  { link: "/shipment", icon: <LocalShippingIcon />, label: "shipment" },
  {
    link: "/shipment/planning",
    icon: <ChildCare />,
    label: "shipmentPlanning",
  },
  { link: "/subscription", icon: <ReceiptIcon />, label: "subscription" },
  { link: "/staff", icon: <AssignmentIndIcon />, label: "staff" },
  { link: "/warehouse", icon: <BusinessIcon />, label: "warehouse" },
  { link: couponPath, icon: <MoneyIcon />, label: "coupon" },
  {
    link: "/warehouse/transfer",
    icon: <CallSplitIcon />,
    label: "warehouseTransfer",
  },
  {
    link: "/return",
    icon: <SettingsBackupRestore />,
    label: "return",
  },
];

interface BottomNavigationPortalProps extends DatadogAttributes {
  readonly activeMenu: MenuEnum;
}

const BottomNavigationPortal: FC<BottomNavigationPortalProps> = (
  props: BottomNavigationPortalProps,
) => {
  const { activeMenu, ddAllowPrivacy } = props;
  const router = useRouter();
  const body = document.querySelector("body");

  if (body === null) {
    return null;
  }

  return createPortal(
    <BottomNavigationContainer>
      <Tabs
        value={
          menuItems.filter((menuItem) => activeMenu === menuItem.label)[0]
            ?.label
        }
        variant="scrollable"
        scrollButtons={false}
        indicatorColor="primary"
        textColor="primary"
      >
        {menuItems.map((menuItem) => (
          <TabLayout
            key={menuItem.label}
            label={mainMenuLabels[menuItem.label]}
            icon={menuItem.icon}
            value={menuItem.label}
            onClick={link(router, menuItem.link, menuItem.link)}
            {...addDDAllowPrivacy(ddAllowPrivacy)}
          />
        ))}
      </Tabs>
    </BottomNavigationContainer>,
    body,
  );
};

interface MainLayoutProps extends DatadogAttributes {
  readonly title: string;
  readonly mode?: ScreenMode;
  readonly activeMenu: MenuEnum;
  readonly children: React.ReactNode;
}

const MobileLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  children,
  title,
  activeMenu,
  ddAllowPrivacy,
}) => {
  return (
    <>
      <FullMainContent>
        <PageTitle variant="h4">{title}</PageTitle>
        <FilterTags />
        {children}
      </FullMainContent>
      <BottomNavigationPortal
        activeMenu={activeMenu}
        ddAllowPrivacy={ddAllowPrivacy}
      />
    </>
  );
};

const BrowserLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  children,
  title,
  activeMenu,
  ddAllowPrivacy,
}) => {
  const router = useRouter();
  const { isMenuOpened } = useMenuDisclosure();
  const getActiveColorName = (
    name: MenuEnum,
  ): React.ComponentProps<typeof MenuButton>["color"] => {
    return activeMenu === name ? "primary" : "inherit";
  };
  return (
    <>
      {isMenuOpened && (
        <HeaderBarSticky>
          <HeaderBar>
            {menuItems.map((menuItem) => {
              return (
                <MenuButtonContainer key={menuItem.label}>
                  <div
                    role="tab"
                    tabIndex={0}
                    onKeyDown={() => {
                      link(router, menuItem.link, menuItem.link);
                    }}
                    onClick={link(router, menuItem.link, menuItem.link)}
                  >
                    <MenuButton
                      variant="outlined"
                      color={getActiveColorName(menuItem.label)}
                      size="large"
                      startIcon={menuItem.icon}
                      sx={{
                        justifyContent: "start",
                      }}
                      {...addDDAllowPrivacy(ddAllowPrivacy)}
                    >
                      {mainMenuLabels[menuItem.label]}
                    </MenuButton>
                  </div>
                </MenuButtonContainer>
              );
            })}
          </HeaderBar>
        </HeaderBarSticky>
      )}

      <MainContent>
        <PageTitle variant="h4" {...addDDAllowPrivacy(ddAllowPrivacy)}>
          {title}
        </PageTitle>
        <FilterTags />
        <MainLayoutErrorBoundary>
          {router.isReady && children}
        </MainLayoutErrorBoundary>
      </MainContent>
    </>
  );
};
const MainLayout: FC<MainLayoutProps> = (props: MainLayoutProps) => {
  const { title, children, activeMenu, mode, ddAllowPrivacy } = props;
  const { fullScreen } = useFullScreenDisclosure();

  const healthResult = useMadrasAPI("/api/v1/health");

  const isMobile = useIsMobile();
  return (
    <MainContainer
      maxWidth={fullScreen ? false : "xl"}
      className={fullScreen ? "is-fullscreen" : ""}
    >
      <ModeContext.Provider value={mode}>
        {isMobile ? (
          <MobileLayout
            activeMenu={activeMenu}
            title={title}
            ddAllowPrivacy={ddAllowPrivacy}
          >
            {children}
          </MobileLayout>
        ) : (
          <BrowserLayout
            activeMenu={activeMenu}
            title={title}
            ddAllowPrivacy={ddAllowPrivacy}
          >
            {children}
          </BrowserLayout>
        )}

        <Footer>
          <Logo>
            <img src="/images/logo_toysub_web.svg" alt="Toysub" width="70px" />
          </Logo>
          <VersionInfo {...addDDAllowPrivacy(ddAllowPrivacy)}>
            frontend: {process.env.APP_VERSION_HASH}
          </VersionInfo>
          <VersionInfo {...addDDAllowPrivacy(ddAllowPrivacy)}>
            api: {healthResult.data?.hash?.slice(0, 7)}
          </VersionInfo>
        </Footer>
      </ModeContext.Provider>
    </MainContainer>
  );
};

export default MainLayout;
