import { type ParsedUrlQuery } from "querystring";
import type * as queryString from "@/features/list-screen/constants/query-string";
import {
  createSearchQuery,
  type SearchQuery,
  type SearchSettingOptions,
} from "@/helper/search-setting";
import { isFalsyString } from "@/helper/string";

/**
 * 検索設定が必要な場合、検索設定を付与する
 * @param query
 * @param searchSettings あいまい検索などの検索設定
 */
export const appendSearchSettings = <
  T extends ParsedUrlQuery & { [key in typeof queryString.KEYWORD]?: string },
>(
  query: T,
  searchSettings: SearchSettingOptions,
): T & Partial<SearchQuery> => {
  if (isFalsyString(query.keyword)) {
    return query;
  }
  return {
    ...query,
    ...createSearchQuery(searchSettings),
  };
};
