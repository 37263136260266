// TODO: 定数の配置場所を再検討する ( https://github.com/torana-us/madras/issues/895 )
/**
 * 一覧画面でページ指定に用いる
 */
export const PAGE = "page";

/**
 * 一覧画面でキーワード検索に用いる
 */
export const KEYWORD = "keyword";

/**
 * 一覧画面でフィルター検索に用いる
 */
export const FILTER = "filter";

/**
 * 一覧画面において、 pageId を value としてもつ key
 */
export const FILTER_IN = "filterIn";

/**
 * 一覧画面において、 ソート対象のカラムを指定する
 */
export const FILTER_ORDER_BY = "orderBy";

/**
 * 一覧画面において、 ソートの昇順・降順を指定する
 */
export const FILTER_ORDER = "order";
