export const mainMenuLabels = {
  customer: "顧客管理",
  toy: "おもちゃ管理",
  plan: "プランシート管理",
  planner: "ﾌﾟﾗﾝﾅｰﾒｯｾｰｼﾞ管理",
  inventory: "棚卸し管理",
  inspection: "検品管理",
  stock: "仕入れ管理",
  shipment: "発送管理",
  shipmentPlanning: "プラン管理",
  subscription: "料金コース管理",
  staff: "スタッフ管理",
  warehouse: "事業所・倉庫管理",
  perks: "福利厚生",
  coupon: "クーポン管理",
  warehouseTransfer: "事業所間移動管理",
  return: "返却管理",
};
