import { type SWRResponse } from "swr";
import { swrConfigValue } from "@/configs/swr";

export interface Context {
  isCreateMode?: boolean;
}

/**
 * @note
 * revalidateOnMount = false の場合、設定によっては data, error, isLoading, isValidating
 * がそれぞれ、 undefined, undefined, false, false となり、リクエストが送信されない状態が生まれる。
 * （ cf. https://github.com/vercel/swr/issues/943 ）
 * isLoading, isValidating が false であっても、データがなければ loading 状態と判定したいので、
 * この場合、当関数を利用する。現状 revalidateOnMount = false とするのは test 時のみ。
 *
 * 新規追加の文脈では、data は undefined となることを考慮する。
 */
const isSwrResponseLoadingForEdgeCase = (
  res: SWRResponse<any, any>,
  context?: Context,
) => {
  if (context?.isCreateMode === true) {
    return res.isLoading || res.isValidating;
  }
  return res.isLoading || res.isValidating || res.data === undefined;
};

/**
 * @note
 * revalidate 時にも loading indicator を出したいため、isValidating も参照する。
 * cf. https://swr.vercel.app/ja/docs/advanced/understanding
 */
const isSwrResponseLoading = (
  res: SWRResponse<any, any>,
  context?: Context,
) => {
  if (!swrConfigValue.revalidateOnMount) {
    return isSwrResponseLoadingForEdgeCase(res, context);
  }
  return res.isLoading || res.isValidating;
};

/**
 * SWRResponse の プロパティにアクセスしないと再レンダリングされないのでその対策をする。
 * `responses.some(isSwrResponseLoading)` ではアクセスできない。
 * cf. https://swr.vercel.app/docs/advanced/performance#dependency-collection
 */
const someResponseIsLoading = (
  responses: readonly SWRResponse<any, any>[],
  context?: Context,
): boolean => {
  let isLoading = false;
  responses.forEach((val) => {
    if (isSwrResponseLoading(val, context)) {
      isLoading = true;
    }
  });
  return isLoading;
};

export const isLoading = (
  responses: readonly SWRResponse<any, any>[],
  context?: Context,
): boolean => {
  return someResponseIsLoading(responses, context);
};

export const getFirstError = <Error = any>(
  responses: readonly SWRResponse<any, Error>[],
): Error | undefined =>
  responses.reduce<Error | undefined>(
    (acc, res) => acc ?? res.error,
    undefined,
  );

/**
 * api の状態を取得する共通処理。
 * @note
 * SWRResponse への依存を吸収する。
 */
export const getApiState = (
  responses: readonly SWRResponse<any, any>[],
  context?: Context,
): {
  isLoading: boolean;
  error: any;
} => {
  return {
    isLoading: isLoading(responses, context),
    error: getFirstError(responses),
  };
};
