export interface SearchSettingOptions {
  enableSearchInComment?: boolean;
  enableFuzziness?: boolean;
}

const appendSearchSetting = (
  searchParams: URLSearchParams,
  options: SearchSettingOptions,
): URLSearchParams => {
  if (!options?.enableSearchInComment) {
    searchParams.set("options[with_comment]", "0");
  }
  if (options?.enableFuzziness) {
    searchParams.set("options[fuzziness]", "1");
  }
  return searchParams;
};

export interface SearchQuery {
  "options[with_comment]"?: "0";
  "options[fuzziness]"?: "1";
}

/**
 * 引数`options`より、検索設定オブジェクトを生成する。
 * @param options
 * @param keyword
 * @returns
 */
const createSearchQuery = (options: SearchSettingOptions): SearchQuery => {
  const ret: SearchQuery = {};
  if (!options?.enableSearchInComment) {
    ret["options[with_comment]"] = "0";
  }
  if (options?.enableFuzziness) {
    ret["options[fuzziness]"] = "1";
  }
  return ret;
};

export { appendSearchSetting, createSearchQuery };
