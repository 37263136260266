import type * as React from "react";
import { type NextRouter } from "next/router";

const link = (
  router: NextRouter | undefined,
  href: string | undefined,
  as?: string,
  isBlank?: boolean,
) => {
  return (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (router === undefined || href === undefined) return;

    const pressed = e.ctrlKey || e.metaKey || e.shiftKey;
    if (pressed || isBlank === true) {
      window.open(as ?? href, "_blank");
      return;
    }
    router.push(href, as);
  };
};

export default link;
