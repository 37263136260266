import rison from "rison";
import * as queryString from "@/features/list-screen/constants/query-string";

/**
 * * 引数`query`の`filter` key の value をデコードする。
 */
export const createDecodedFilterParams = <
  T extends { [key in typeof queryString.FILTER]?: string },
>(
  query: T,
) => {
  return rison.decode_object<Record<string, string>>(
    query[queryString.FILTER] ?? "",
  );
};
