import {
  createSortQueryLoose,
  type Sort,
  createSortQueries,
} from "@/api/swr/helpers/query";
import { type queryString } from "@/features/list-screen";

/**
 * * `orderBy`,`order`をkeyとするkey/valueペアをapi用クエリパラメータのkey/valueに変換する。
 * * `defaultSort` が渡されている場合、`query`のソート設定が優先される。
 * @note
 * api の仕様上、クエリパラメータの順序でソートの優先順位が決定される。
 */
export const convertSortKey = <
  T extends {
    [key in typeof queryString.FILTER_ORDER_BY]?: string;
  } & { [key in typeof queryString.FILTER_ORDER]?: string },
>(
  query: T,
  defaultSort?: Sort | Sort[],
): Omit<
  T,
  typeof queryString.FILTER_ORDER_BY | typeof queryString.FILTER_ORDER
> & {
  [key: `sort${string}`]: "asc" | "desc";
} => {
  const { order, orderBy, ...rest } = query;

  return {
    ...createSortQueryLoose({ order, by: orderBy }),
    ...createSortQueries(defaultSort),
    ...rest,
  };
};
