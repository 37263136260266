import useSWR from "swr";
import { useAtom } from "jotai";
import { describeEndpointPathAtom } from "@/features/list-screen/hooks/describe/atoms";
import { fetcher } from "@/api/swr/helpers/fetcher";
import { type ExtendedDescribe } from "@/features/describes/DescribeSlice";
import { useRevalidate } from "@/api/swr/hooks";

/**
 * @returns
 * * `~/describe` endpoint のデータ
 * * データ未取得の場合、または、エラーの場合 undefined
 */
export const useDescribes = (): ExtendedDescribe[] | undefined => {
  const [describeEndpointPath, setDescribeEndpointPath] = useAtom(
    describeEndpointPathAtom,
  );
  const describeResponse = useSWR<ExtendedDescribe[]>(
    describeEndpointPath,
    fetcher,
  );

  useRevalidate({ response: describeResponse, key: describeEndpointPath });

  // endpoint が存在しない場合などの考慮
  if (describeResponse.error !== undefined) {
    setDescribeEndpointPath(null);
    return undefined;
  }

  return describeResponse.data;
};
