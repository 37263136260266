import { noop } from "remeda";
import { useMadrasAPI } from "@/api/hooks";

/**
 * @NOTE このカスタムフックはsrc/components/fragments/Main.tsx コンポーネントの内部でのみ利用されることを前提にしているので
 * response.data が undefined になることはない。
 */
export const useMe = () => {
  return useMadrasAPI("/api/v1/staff/me", {
    swrOptions: {
      revalidateOnMount: false,
      onErrorRetry: noop,
    },
    immutable: true,
  }).data!;
};
