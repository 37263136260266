import { appendSearchSettings } from "./appendSearchSettings";
import { type WatchKeys } from "../../types";
import {
  convertSortKey,
  convertFilterKey,
  appendIgnoreElasticSearchOption,
} from "@/features/list-screen/helpers/";
import { type OptionForVariableQuery } from "@/api/types";
import { type SearchSettingOptions } from "@/helper/search-setting";

export const convertQuery = (
  watched: Record<WatchKeys, string | undefined>,
  localStorageOption: SearchSettingOptions,
  sortOption?: OptionForVariableQuery["sort"],
) => {
  // sort のための query は順番によりソート結果が変わるため、特別に扱う
  const convertedSort = convertSortKey(watched, sortOption);

  const convertedFilter = convertFilterKey(convertedSort);
  let ret = appendSearchSettings(convertedFilter, localStorageOption);
  ret = appendIgnoreElasticSearchOption(ret, watched);
  return ret;
};
