import { queryString } from "@/features/list-screen/constants";

/**
 * 一覧画面でユーザーの操作により変更されるクエリストリングのキーを定義する。
 */
export const watchKeys = [
  queryString.PAGE,
  queryString.KEYWORD,
  queryString.FILTER,
  queryString.FILTER_ORDER_BY,
  queryString.FILTER_ORDER,
] as const;
