import { type FC, type PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FallbackError } from "../../fallbacks";
import { sendLogOnFallback } from "../helper";

/**
 * @note
 * ErrorBoundaryは仕様的にキャッチできない場所でthrowされたエラーをキャッチすることができない
 * その場合はpages/_error.tsxでキャッチされる
 *
 * コンポーネント自体はAPIResponseErrorBoundaryと同じであるが、将来的にエラー表示の仕様が変わることを想定して
 * 別コンポーネントとして定義している
 */
export const FormErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackError}
      onError={sendLogOnFallback}
    >
      {children}
    </ErrorBoundary>
  );
};
