import { z } from "zod";
import { type TagType } from "../FilterTags";

/**
 * uuid, Uuid（大文字小文字問わず）を検出する
 * @param key
 * @returns
 */
const matchKeyNameWithUuid = (key: string): boolean => /uuid/i.test(key);

/**
 * コンボボックスで選択するタイプのフィルタのTagTypeの値のみtrueを返す
 * MADRASの命名規則上コンボボックスのTagTypeのキーにUUIDの文字が入らないため
 * このような判定をする
 * 命名規則が見直された場合修正が必要
 * trueを返すもの例
 *
 * {
 *   key: "family_customer_type" // こちらにuuidという文字が入っていない
 *   value: "01234567-aaaa-bbbb-cccc-123456abcdefg" // 値はuuidのフォーマットのもの
 * }
 *
 * @param tag
 * @returns
 */
export const isFilterTypeComboBox = (tag: TagType): boolean => {
  const uuidSchema = z.string().uuid(tag.value);

  return (
    !matchKeyNameWithUuid(tag.key) && uuidSchema.safeParse(tag.value).success
  );
};
