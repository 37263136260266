import styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { type FC, useState, type MouseEvent } from "react";
import Link from "next/link";
import {
  Button,
  Container,
  Divider,
  FormControlLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FaceIcon from "@mui/icons-material/Face";
import { useRouter } from "next/router";
import { Global, css } from "@emotion/react";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";
import {
  useCommentDisclosure,
  useExperimentalFeatureDisclosure,
  useFullScreenDisclosure,
  useMenuDisclosure,
} from "@/components/providers/local-storage";
import { useStickyTops } from "@/hooks/useStickyTops";
import { useIsMobile } from "@/hooks/useMediaQuery";
import { useMe } from "@/hooks/useMe";
import { useSignIn } from "@/hooks/useSignIn";

const GlobalHeader = styled.div`
  // Force to change the width because MobileView/BrowserView generate div inside.
  // And because It cannot change the value by outside.
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters > div {
    width: 100%;
  }
`;
const HeaderMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  &:not(.is-fullscreen) {
    max-width: 1120px;
  }
`;

const UserInfoButton = styled(Button)`
  span {
    color: #ffffff;
  }
`;

const GlobalActionButtons = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 12px;
  padding: 0 15px;
  &:not(.is-fullscreen) {
    padding: 0;
    max-width: 1170px;
  }
`;

const LeftActionButtons = styled.div``;
const RightActionButtons = styled.div`
  display: flex;
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: #888888;
  }
`;

const Header: FC<DatadogAttributes> = ({ ddAllowPrivacy }) => {
  const router = useRouter();
  const { fullScreen, toggleFullScreen } = useFullScreenDisclosure();
  const { isMenuOpened, toggleMenu } = useMenuDisclosure();
  const { isCommentOpen, toggleCommentArea } = useCommentDisclosure();
  const { experimentalFeature, toggleExperimentalFeature } =
    useExperimentalFeatureDisclosure();
  const me = useMe();
  const { logout } = useSignIn();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { paddingTop } = useStickyTops();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const header = (
    <>
      <Global
        styles={css`
          :target {
            padding-top: ${paddingTop.anchorTitle}px;
        `}
      />
      <HeaderMenu className={fullScreen ? "is-fullscreen" : ""}>
        <Typography
          component={Link}
          variant="h6"
          style={{ cursor: "pointer", color: "white" }}
          {...addDDAllowPrivacy(ddAllowPrivacy)}
          href="/"
        >
          MADRAS
        </Typography>

        <UserInfoButton
          variant="text"
          startIcon={<AccountCircle />}
          onClick={handleMenu}
          {...addDDAllowPrivacy(ddAllowPrivacy)}
        >
          {me?.name} さん
        </UserInfoButton>
      </HeaderMenu>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={() => router.push("/perks")}>
          <ListItemIcon style={{ minWidth: "32px" }}>
            <FaceIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" {...addDDAllowPrivacy(ddAllowPrivacy)}>
            福利厚生
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push("/customer/bills/add");
          }}
        >
          <ListItemIcon style={{ minWidth: "32px" }}>
            <CloudUploadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" {...addDDAllowPrivacy(ddAllowPrivacy)}>
            おもちゃ購入CSV
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout}>
          <ListItemIcon style={{ minWidth: "32px" }}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" {...addDDAllowPrivacy(ddAllowPrivacy)}>
            ログアウト
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );

  const isMobile = useIsMobile();

  return (
    <>
      <GlobalHeader>
        <AppBar position="static">
          <Toolbar>
            {isMobile ? (
              header
            ) : (
              <Container maxWidth={fullScreen ? false : "xl"}>
                {header}
              </Container>
            )}
          </Toolbar>
        </AppBar>
      </GlobalHeader>
      <GlobalActionButtons className={fullScreen ? "is-fullscreen" : ""}>
        <LeftActionButtons />
        <RightActionButtons>
          <>
            {!isMobile && (
              <>
                <FormControlLabel
                  label="フルスクリーンモード"
                  className="label"
                  {...addDDAllowPrivacy(ddAllowPrivacy)}
                  control={
                    <Switch
                      color="primary"
                      size="small"
                      checked={fullScreen}
                      onChange={() => toggleFullScreen()}
                    />
                  }
                />
                <FormControlLabel
                  label="メニュー表示"
                  className="label"
                  {...addDDAllowPrivacy(ddAllowPrivacy)}
                  control={
                    <Switch
                      color="primary"
                      size="small"
                      checked={isMenuOpened}
                      onChange={toggleMenu}
                    />
                  }
                />
              </>
            )}
            <FormControlLabel
              label="コメント表示"
              className="label"
              {...addDDAllowPrivacy(ddAllowPrivacy)}
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={isCommentOpen}
                  onChange={toggleCommentArea}
                />
              }
            />
            <FormControlLabel
              label="実験的機能"
              className="label"
              {...addDDAllowPrivacy(ddAllowPrivacy)}
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={experimentalFeature}
                  onChange={toggleExperimentalFeature}
                />
              }
            />
          </>
        </RightActionButtons>
      </GlobalActionButtons>
    </>
  );
};

export default Header;
