import { css } from "@emotion/react";
import { breakpoints } from "../mui/breakpoint";

/**
 * Styled-componentやemotionの中でメディアクエリを書くための簡単なラッパー関数
 */

export const mediaQuery = {
  mobile: (style: TemplateStringsArray) => {
    const template = `
      @media (max-width: ${breakpoints.sm}px) {
        ${style}
      }
    `;
    return css(template);
  },
  tablet: (style: TemplateStringsArray) => {
    const template = `
    @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
      ${style}
    }
  `;
    return css(template);
  },
  desktop: (style: TemplateStringsArray) => {
    const template = `
    @media (min-width: ${breakpoints.md}px) {
      ${style}
    }
  `;
    return css(template);
  },
};
