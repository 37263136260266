import { type ReactNode } from "react";
import { type SWRResponse } from "swr";
import { TableContainer } from "./TableContainer";
import { ApiStateController } from "@/components/fragments/api-state-controller";
import { APIResponseErrorBoundary } from "@/components/fragments/errors/error-boundaries";

export interface ListScreenProps {
  // biome-ignore lint/suspicious/noExplicitAny :[AUTOMATICALLY-INSERTED] Unexpected any. Specify a different type.
  readonly responses?: readonly SWRResponse<any, any>[];
  readonly toolbar?: ReactNode;
  /**
   * 状態(loading, error)を監視する対象のレスポンスの配列。
   */
  readonly children: ReactNode;
}

/**
 * @description
 * - 一覧画面のレイアウト・デザインを共通化する。（※ 現状薄いラッパーに止まるが、今後レイアウト・デザインを変更する際に修正箇所を局所化できる。）
 * - `responses` の状態を見て、エラーハンドンリングやローディング画面表示のコントロールを行う。
 * - responsesにerrorがある場合は、ErrorBoundaryにエラーをthrowし、FallbackErrorを表示する。
 */
export function ListScreen({
  toolbar,
  responses = [],
  children,
}: ListScreenProps) {
  return (
    <>
      {toolbar}
      <APIResponseErrorBoundary>
        <ApiStateController responses={responses}>
          <TableContainer>{children}</TableContainer>
        </ApiStateController>
      </APIResponseErrorBoundary>
    </>
  );
}
