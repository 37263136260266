import { type ParsedUrlQuery } from "querystring";
import { queryString, isIgnoreElasticSearchOptionKey } from "../constants";
import { isFalsyString } from "@/helper/string";

/**
 * Elasticsearch をOFFにするかどうかを判定する。
 */
const isIgnoreElasticSearch = (
  filter: string | undefined,
  keyword: string | undefined,
): boolean => {
  return !isFalsyString(filter) && isFalsyString(keyword);
};

/**
 * ParsedUrlQuery に Elasticsearch をOFFにするオプションを付与する。
 */
export const appendIgnoreElasticSearchOption = <
  T extends ParsedUrlQuery,
  U extends { [key in typeof queryString.FILTER]?: string } & {
    [key in typeof queryString.KEYWORD]?: string;
  },
>(
  targetQuery: T,
  intactQuery: U,
): T => {
  if (
    isIgnoreElasticSearch(
      intactQuery[queryString.FILTER],
      intactQuery[queryString.KEYWORD],
    )
  ) {
    return {
      ...targetQuery,
      [isIgnoreElasticSearchOptionKey]: "1",
    };
  }
  return targetQuery;
};
