import rison from "rison";
import { type ParsedUrlQuery } from "querystring";
import { queryString } from "@/features/list-screen/constants";

/**
 * 引数`encodableObject` をエンコードした値を `filter` key の value として 引数`query` に付与する。
 */
export const appendFilterKey = <T extends ParsedUrlQuery>(
  query: T,
  encodableObject: Parameters<typeof rison.encode_object>[0],
): T & { [key in typeof queryString.FILTER]?: string } => {
  return {
    ...query,
    [queryString.FILTER]: rison.encode_object(encodableObject),
  };
};
