import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { describeEndpointPathAtom } from "@/features/list-screen/hooks/describe/atoms";

/**
 * 引数`apiEndpoint`に`/describe`という path を追加したエンドポイントを、
 * `describeEndpointAtom` に設定する。
 * @param apiEndpoint  `${apiEndpoint}/describe` というエンドポイントが存在するようなエンドポイント
 * @param disableDescribe true の場合、この Custom Hook は何もしない。
 */
export const useSetDescribeEndpoint = (
  path: string,
  disableDescribe?: boolean,
) => {
  const setDescribeEndpointAtom = useSetAtom(describeEndpointPathAtom);
  useEffect(() => {
    if (disableDescribe === true) {
      return;
    }
    setDescribeEndpointAtom(`${path}/describe`);
  }, [path, disableDescribe, setDescribeEndpointAtom]);
};
