import { useRouter } from "next/router";
import { useIsMobile } from "./useMediaQuery";
import { HEADER_BAR_STICKY_HEIGHT } from "@/components/fragments/main-layout/MainLayout";
import { useMenuDisclosure } from "@/components/providers/local-storage";

interface Tops {
  top: {
    tabs: number;
    actionButtonGroups: number;
  };
  paddingTop: {
    anchorTitle: number;
  };
}

const TABS_HEIGHT = 50;
const ACTION_BUTTON_GROUPS_WITH_TOC = 118;

/**
 * 詳細ページにタブを持っているページ
 */
const pagesWithTab = ["customer", "toy"] as const;
type PageWithTab = (typeof pagesWithTab)[number];

const existCurrentPageWithTab = (path: string): path is PageWithTab => {
  return pagesWithTab.some((page) => page === path);
};

/**
 * アクションボタングループのtopの値を返す
 * メインメニューの表示・非表示と、タブがあるかをチェックする
 */
const getActionButtonGroupsTop = ({
  isMenuOpened,
  existTabContainer,
  isMobile,
}: {
  isMenuOpened: boolean;
  existTabContainer: boolean;
  isMobile: boolean;
}): number =>
  isMobile
    ? 0
    : (isMenuOpened ? HEADER_BAR_STICKY_HEIGHT : 0) +
      (existTabContainer ? TABS_HEIGHT : 0);

export const useStickyTops = (): Tops => {
  const router = useRouter();
  const firstPath = router?.pathname?.split("/")?.[1] ?? "";
  const { isMenuOpened } = useMenuDisclosure();
  const existTabContainer = existCurrentPageWithTab(firstPath);
  const isMobile = useIsMobile();
  const actionButtonGroupsTop = getActionButtonGroupsTop({
    isMenuOpened,
    existTabContainer,
    isMobile,
  });

  return {
    top: {
      actionButtonGroups: actionButtonGroupsTop,
      tabs: isMenuOpened ? HEADER_BAR_STICKY_HEIGHT : 0,
    },
    paddingTop: {
      anchorTitle: actionButtonGroupsTop + ACTION_BUTTON_GROUPS_WITH_TOC,
    },
  };
};
