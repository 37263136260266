export function filterKeyInObject(
  o: any,
  filter: (v: string | undefined, theJoinedKeyName: string[]) => string,
  joinedKeyName: string[] = [],
): any {
  if (Array.isArray(o)) {
    o = [...o];
    return o.map((v: any) =>
      filterKeyInObject(v, filter, [...joinedKeyName, "*"]),
    );
  }
  if (typeof o === "object") {
    o = { ...o };
    return Object.keys(o).reduce((carry, keyName) => {
      carry[filter(keyName, joinedKeyName)] = filterKeyInObject(
        o[keyName],
        filter,
        [...joinedKeyName, keyName],
      );
      return carry;
    }, {} as any);
  }
  return o;
}

export function filterValueInObject(
  o: any,
  filter: (v: any, theJoinedKeyName: string[]) => any,
  joinedKeyName: string[] = [],
): any {
  if (Array.isArray(o)) {
    o = [...o];
    return o.map((v: any) =>
      filterValueInObject(v, filter, [...joinedKeyName, "*"]),
    );
  }
  if (typeof o === "object") {
    o = { ...o };
    return Object.keys(o).reduce((carry, keyName) => {
      const result = filterValueInObject(o[keyName], filter, [
        ...joinedKeyName,
        keyName,
      ]);
      if (result === undefined) {
        return carry;
      }
      carry[keyName] = result;
      return carry;
    }, {} as any);
  }
  return filter(o, joinedKeyName);
}
