import { type FC, type PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FallbackError } from "../../errors/fallbacks";
import { sendLogOnFallback } from "../../errors/error-boundaries/helper";

/**
 * @note
 * MainLayoutで利用する。
 */
export const MainLayoutErrorBoundary: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackError}
      onError={sendLogOnFallback}
    >
      {children}
    </ErrorBoundary>
  );
};
